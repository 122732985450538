






























import Vue from "vue";
import SubHeader from "@/views/layout/header/SubHeader.vue";
import Search from "@/components/Search.vue";
import CardNotification from "@/components/card/CardNotification.vue";
import { Component } from "vue-property-decorator";
import MenuFooter from "@/views/layout/footer/MenuFooter.vue";
// import FilterComponent from "@/components/FilterComponent.vue";
@Component({
  components: {
    SubHeader,
    Search,
    CardNotification,
    MenuFooter,
    // FilterComponent,
  },
})
export default class ListNotification extends Vue {
  titlePage = "Notification";
  listTicket = [
    {
      id: "1",
      date: "21/JAN/2022",
      time: "12.30",
      title: "Cake Engine Breaking Down",
      subTitle: "Lorem Ipsum is simply text",
      status: "new",
      isOpen: "0",
    },
    {
      id: "2",
      date: "21/JAN/2022",
      time: "12.30",
      title: "Lorem Ipsum",
      subTitle: "Lorem Ipsum is simply text",
      status: "on progress",
      isOpen: "1",
    },
    {
      id: "3",
      date: "21/JAN/2022",
      time: "12.30",
      title: "Cake Engine Breaking Down",
      subTitle: "Lorem Ipsum is simply text",
      status: "on progress",
      isOpen: "1",
    },
    {
      id: "4",
      date: "21/JAN/2022",
      time: "12.30",
      title: "Cake Engine Breaking Down",
      subTitle: "Lorem Ipsum is simply text",
      status: "on progress",
      isOpen: "1",
    },
  ];
}
